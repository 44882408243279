.Underline {
  padding-bottom: 10px;
  border-bottom: 1px solid #d0d0d0;
  margin-bottom: 10px;
}
.bannerBox /deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}
.bannerBox /deep/ .el-upload-list__item .el-icon-close-tip {
  z-index: 9999;
}
.live /deep/ .el-upload {
  width: 286px;
}
.shareCover /deep/ .el-upload {
  width: 186px;
  height: 268px !important;
}
